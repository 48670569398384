.contact {
    background: #051522;
    padding: 2rem 0;
}

.con-heading>h1 {
    color: #FFFFFF;
    text-align: center;
    font-size: 3rem;
    line-height: 5rem;
    font-family: 'Montserrat', sans-serif;
    margin-top: 1rem;
}

.lbl {
    color: #FFFFFF;
    font-size: 1rem;
    position: absolute;
    font-family: 'Montserrat', sans-serif;
}

.captcha {
    display: inline-block;
    margin-top: 20px;
}

.con-body {
    justify-content: space-around;
    padding: 0 5% 0 5%;
}

.contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.con-left {
    margin: 2rem 0 3rem 0;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
}

.shade {
    overflow: auto;
    background-image: linear-gradient(150deg, rgba(0, 0, 0, 0.65), transparent);
}

.blackboard {
    position: relative;
    width: 640px;

}

input {
    width: 14em;
    border: 0;
}

input,
textarea {
    vertical-align: middle;
    background: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    color: rgba(4, 4, 4, 0.8);
    line-height: 1.5rem;
    outline: none;
    border-radius: 5px;
    margin: 19px 19px 19px 0px;
    margin-top: 30px;
    padding: 0.5rem 1rem;
}

textarea {
    padding: 1rem 1rem;
    width: 510px;
    height: 110px;
    line-height: 1em;
    border-style: dotted;
}

input[type="submit"] {
    cursor: pointer;
    color: #070707b3;
    line-height: 1em;
    padding: 10px 50px;
    border: 0;
    font-size: 1.25rem;
    border-radius: 6px;
    transition: all 0.2s ease;
    margin-bottom: 50px;
}

input[type="submit"]:hover {
    color: #000000;
    text-shadow: 0px 0px 1px white;
}

input[type="submit"]:focus {
    background: rgba(238, 238, 238, 0.2);
    color: rgba(238, 238, 238, 0.2);
}

::-moz-selection {
    text-shadow: none;
}

::selection {
    text-shadow: none;
}

.gmap_canvas {
    text-align: center;
    overflow: hidden;
    background: none !important;
}

.gmap_canvas>iframe {
    width: 39vw;
    height: 55vh;
    overflow: hidden;
    margin: 0;
    border-radius: 10px;
    border: 0;
}

@media (max-width: 650px) {
    .contact-container {
        display: revert;
    }

    .con-heading>h1 {
        font-size: 3em;
    }

    .con-body {
        padding: 0 1.8rem;
        flex-direction: column;
    }

    .lbl {
        font-size: 1rem;
        position: relative;
    }

    .scndclm {
        display: revert;
    }

    input,
    textarea {
        margin: 8px 0 13px;
        padding: 10px;
        width: 100%;
    }

    .captcha {
        width: 92%;
    }

    .contact {
        width: 100%;
    }

    form {
        display: revert;
    }

    .gmap_canvas>iframe {
        width: 100%;
        height: 30vh;
        margin: 30px;
        padding: 0;
        border-radius: 10px;
    }

    .gmap_canvas {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;

    }
}