.about__container {
    align-items: center;
    text-align: justify;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3rem 0 4rem 0;
}

.about__content {
    width: 40%;
}

.about__description1 {
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
    font-size: 1.6rem;
    padding: 1.5rem 0;
}

.about__description2 {
    color: #FFFFFF;
    font-size: 1.1rem;
    font-family: 'Roboto', sans-serif;
}

#about {
    background: #051522;
}

.about__img {
    overflow-x: hidden;
    width: 550px;
    padding-left: 2rem;
}


.about__title {
    color: #FFFFFF;
    font-size: 3rem;
    line-height: 6rem;
    font-family: 'Montserrat', sans-serif;
}



@media screen and (max-width: 700px) {
    .about__container {
        align-items: center;
        font-size: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .about__img {
        width: 280px;
        margin: 0px;
        padding-left: 0px;
        justify-content: center;
        align-items: center;
    }

    .about__content {
        width: 85%;
    }

    .about__description1 {
        font-size: 1.3rem;
        text-align: center;
    }

    .about__description2 {
        text-align: justify;
        font-size: 1rem;
        padding: 5px;
    }

    .about__title {
        text-align: center;
        font-size: 2.5rem;
        line-height: 8rem;
    }
}