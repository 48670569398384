.footer {
    align-items: center;
    text-align: justify;
    display: flex;
    justify-content: center;
}

.footer-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.content {
    margin: 0 80px;
    width: 87%;
    font-family: 'Roboto', sans-serif;
}

.content>h1 {
    margin-top: 50px;
    width: 80%;
    font-size: 1.7rem;
    font-family: 'Montserrat', sans-serif;
}

.content>h1>span {
    color: #2388e1  ;
}

.cntfooter {
    width: 80%;
}

.menu>ul {
    display: flex;
    list-style: none;
    align-items: center;
    color: #070707b3;
    margin: 1.5rem 55px;
    font-weight: 600;
}

.menu>ul a {
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    color: #000000;
    font-size: 1.2rem;
    margin: 10px;
    transition: all 0.2s ease;
}

.menu>ul a:hover {
    color: #2388e1;
}

.menu>ul>li {
    margin: 0 1rem;
}

.social>ul {
    display: flex;
    list-style: none;
    justify-content: center;
}

.social i {
    transition: all 0.2s ease;
}

.social i:hover {
    transform: scale(1.2);
}

.right {
    display: flex;
    margin: 2.5rem 0;
}

.right>img {
    width: 15rem;
}

.fa-brands {
    margin: 0 1.3rem;
    font-size: 1.5em;
    color: #000000
}

.copyright {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    padding: 1.5rem 1rem;
}

@media (max-width: 650px) {
    .footer-container {
        display: revert;
    }

    .footer-child {
        align-items: center;
        justify-content: center;
        text-align: justify;
    }

    .footer {
        overflow: hidden;
        flex-direction: column;
    }

    .content {
        margin: 0px 60px;
        font-size: 18px;
    }

    .content>h1 {
        font-size: 25px;
    }

    .social>ul {
        font-size: 15px;
        text-align: center;
    }

    .menu>ul {
        text-align: center;
    }

    .menu>ul>li {
        margin: 0;
    }

    .logo-child {
        display: flex;
    }

    .fa-brands {
        margin-top: 1rem;
    }
}