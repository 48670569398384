.child-album{
    background-color: #D9D9D9;
    margin: 25px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.child-album>h3{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
}
.child-album>h4{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #686464;
}
.child-album>p{
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}
.background-card{
    position: absolute;
    width: 17.5vw; 
    border-radius: 10px;
}
.overlay{
    position: relative;
    z-index: 1111;
    width: 14vw;
    height: 14vw;   
    margin: 1.7vw;
    border-radius: 50%;
    object-fit: cover;
}

.icons-member{
    margin: 0;
    padding: 1.5vw 0.8vw;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 1rem;
}
.bottom-icon{
    height: 17vw;
    text-align: center;
    justify-content: center;
    align-items: end;
}

@media screen and (max-width: 800px) and (min-width: 100px){
    .child-album{
        width: 45vw;
        margin: 10px;
        padding-bottom: 10px;
        text-align: center;
    }
    .child-album>h3{
        font-size: 1rem;
    }
    .overlay{
        width: 36vw;
        height: 37vw;
    }
    .background-card{
        width: inherit;
    }
    .icons-member{
        font-size: 1rem;
        padding: 0px 15px;
        margin: 0;
        width: 42vw;
        height: 10vw;
    }
    .bottom-icon{
        height: 50vw;
    }
   
}
