@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

.home {
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;
}

.home::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: url('../../assets/circuit.png');
  background-size: contain;
  z-index: -100;
  opacity: 0.6;
}

.t3>h3 {
  color: #000;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.img-grid {
  padding: 10%;
}
.bigdata{
  font-size: 1.5rem;
}
/* .box1 {
    position: absolute;
    overflow: hidden;
    width: 715px; 
    height: 300px;  
    border: solid 1px #000;
    border-color: #000 transparent transparent transparent;
    border-radius: 50%/100px 100px 0 0;
    transform: rotate(90deg);
} */

.t1 {
  margin-top: 30px;
  font-size: 3rem;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, .2));
}

.t2 {
  font-size: 1.5rem;
  line-height: 5rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;

}

.t3 {
  font-size: 1.1rem;
  line-height: 5rem;
  font-weight: bold;
}

.coehead {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.coehead>div {
  width: 100%;
}

.coehead p {
  width: 100%;
  color: #fff;
  font-size: 3em;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.coehead>div p:nth-child(1) {
  width: 50%;
  position: absolute;
  color: transparent;
  font-family: "Poppins", sans-serif;
  -webkit-text-stroke: 2px #050606;
}

.coehead>div p:nth-child(2) {
  color: #040809;
  font-family: "Poppins", sans-serif;
  animation: animate 4s ease-in-out infinite;
}

/* .coehead>div p:nth-child(3) {
    color: transparent;
    position: absolute;
    font-family: "Poppins", sans-serif;
    -webkit-text-stroke: 2px #050606;
  }
  
  .coehead>div p:nth-child(4) {
    position: absolute;
    color: #040809;
    font-family: "Poppins", sans-serif;
    animation: animate 4s ease-in-out infinite;
  } */

@keyframes animate {

  0%,
  100% {
    clip-path: polygon(0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%);
  }

  50% {
    clip-path: polygon(0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%);
  }
}


@media screen and (max-width: 850px) {
  .home {
    align-items: flex-end;
  }

  .t1 {
    font-size: 1.3rem;
    margin-top: 0;
    line-height: 4rem;
  }

  .t2 {
    margin-top: 30px;
    font-size: 1rem;
  }

  .t3 {
    line-height: 0;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .grid-container .grid-item:nth-of-type(1) {
    order: 2;
  }

  .grid-container .grid-item:nth-of-type(2) {
    order: 1;
  }

  .coehead p {

    font-size: 2em;
  }

  .coehead>div p:nth-child(1) {
    width: 100%;
  }

  .coehead>div p:nth-child(2) {
    width: 100%;
  }

  .toptxt {
    width: 100%;
  }
}