.event{
    height: auto;
    text-align: center;
    font-size: 1.8rem;
}
.event>h1{
    line-height: 7rem;
    letter-spacing: 1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding-top: 3rem;
}
.event>hr{
    width: 97%;
    height: 5px;
    margin-left: 20px;
    background-color: black;
}
.event_loader_card{
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 10px;
    font-size: 1.3rem;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}
.event>.wrapper1{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    padding-bottom: 4rem;
}

.wrapper{
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    margin: 3.5rem 0 0 0;
}
.card{
    width: 18rem;
    height: 22rem;
    cursor: pointer;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 2rem 1rem;
    position: relative;
    display: flex;
    align-items:center;
    box-shadow: 0px 7px 10px rgba(0, 0,0,0.5);
    border: 3px solid #051522;
    border-radius: 6px;
    transition: 0.5s ease-in-out;
}
.card:hover{
    transform: scale(1.2);
}
.card::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #eaf1f1c4, #eaf1f1be);
    z-index: 2;
    transition: 0.5s all;
    opacity: 0;
}
.card:hover::before{
    opacity: 0.2;
}

.card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.card .info{
    position: relative;
    z-index: 3;
    color: #051522;
    opacity: 0;
    font-weight: 600;
    
    transform: translateY(30px);
    transition: 0.5s all;
}
.info>p{
    font-family: 'Roboto', sans-serif;
}
.card:hover .info{
    opacity: 1;
    transform: translateY(0px);
}

@media screen and (min-width: 900px) and (max-width: 1100px){
    .card{
        width: 15rem;
        height: 18rem;
    }
}

@media screen and (min-width: 700px) and (max-width: 900px){
    .card{
        width: 11rem;
        height: 15rem;
    }
}

@media screen and (min-width: 200px) and (max-width: 700px){
    .wrapper{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-bottom: 2rem;
    }
    .event>hr{
        width: 90%;
    }
    .event>h1{
        padding-top: 1.5rem;
    }
}
